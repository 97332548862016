import request from "@/utils/request";

export function getCurrentUserAuthority() {
  return request.get(`/current/user`);
}

export function sendSelectedUserRole(param) {
  return request.get(`/current/user/role`, {
    params: { ...param }
  });
}

export function getOperators() {
  return request.get(`/mobile/user/operation`);
}
