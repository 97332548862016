<template>
  <div class="pageWrapper addMeeting" style="height:100%;overflow:scroll">
    <div class="titleBar">
      工单基本信息
    </div>
    <div class="basicInfoWrapper" style="margin-bottom:40px">
      <van-form ref="formRef" style="margin-top:10px">
        <van-field
          v-model="form.theme"
          required
          name="会议主题"
          label="会议主题"
          placeholder="会议主题"
          :rules="[{ required: true, message: '请填写会议主题' }]"
        />

        <van-field
          readonly
          clickable
          :required="!isJustSaving"
          name="datetimePicker"
          :value="computedStartTime"
          :rules="
            !isJustSaving
              ? [{ required: true, message: '请选择会议开始时间' }]
              : []
          "
          label="会议开始时间"
          placeholder="点击选择时间"
          @click="openTimePicker(1)"
        />

        <van-field
          readonly
          clickable
          :required="!isJustSaving"
          name="datetimePicker"
          :value="computedEndTime"
          label="会议结束时间"
          placeholder="点击选择时间"
          :rules="
            !isJustSaving
              ? [{ required: true, message: '请选择会议结束时间' }]
              : []
          "
          @click="openTimePicker(2)"
        />
        <van-popup v-model="showPicker" position="bottom">
          <van-datetime-picker
            type="datetime"
            :min-date="minDate"
            :max-date="maxDate"
            @confirm="onConfirm"
            @cancel="showPicker = false"
          />
        </van-popup>

        <van-field
          readonly
          clickable
          :required="!isJustSaving"
          name="meetingRoom"
          :value="computedRoom"
          :rules="
            !isJustSaving ? [{ required: true, message: '请选择会议地点' }] : []
          "
          label="会议地点"
          placeholder="点击会议地点"
          @click="openMeetingRoomLevel"
        />
        <van-popup v-model="meetingRoomLVisible" position="bottom">
          <van-picker
            title="会议地点"
            show-toolbar
            :columns="meetingRoomColumns"
            @confirm="onMeetingRoomConfirm"
            @cancel="onMeetingRoomCancel"
          />
        </van-popup>

        <van-field
          readonly
          clickable
          :required="!isJustSaving"
          name="meetingLevel"
          :value="computedLevel"
          :rules="
            !isJustSaving ? [{ required: true, message: '请选择会议等级' }] : []
          "
          label="会议等级"
          placeholder="点击会议等级"
          @click="openMeetingLevel"
        />
        <van-popup v-model="meetingLevelVisible" position="bottom">
          <van-picker
            title="会议等级"
            show-toolbar
            :columns="meetingLevelColumns"
            @confirm="onMeetingLevelConfirm"
            @cancel="onMeetingLevelCancel"
          />
        </van-popup>

        <van-field
          readonly
          clickable
          :required="!isJustSaving"
          name="meetingType"
          :value="computedMeetingType"
          :rules="
            !isJustSaving ? [{ required: true, message: '请选择会议类型' }] : []
          "
          label="会议类型"
          placeholder="点击会议类型"
          @click="openMeetingType"
        />
        <van-popup v-model="meetingTypeVisible" position="bottom">
          <van-picker
            title="会议类型"
            show-toolbar
            :columns="meetingTypeColumns"
            @confirm="onMeetingTypeConfirm"
            @cancel="onMeetingTypeCancel"
          />
        </van-popup>

        <van-field
          readonly
          clickable
          :required="!isJustSaving"
          name="meetingType"
          :value="computedOperators"
          :rules="
            !isJustSaving
              ? [{ required: true, message: '请选择运维工程师' }]
              : []
          "
          label="运维工程师"
          placeholder="点击运维工程师"
          @click="openOperatorsPopup"
        />
        <van-popup
          v-model="meetingOperatorsVisible"
          position="bottom"
          closeable
          @close="handleCloseOperatorsPopup"
        >
          <van-tree-select
            :items="operatorsList"
            :active-id.sync="activeIds"
            :main-active-index.sync="activeIndex"
          />
        </van-popup>

        <van-field
          v-model="form.sponsor"
          :required="!isJustSaving"
          name="主办方"
          label="主办方"
          placeholder="主办方"
          :rules="
            !isJustSaving ? [{ required: true, message: '请填写主办方' }] : []
          "
        />

        <van-field
          v-model="form.contacts"
          :required="!isJustSaving"
          name="联系人"
          label="联系人"
          placeholder="联系人"
          :rules="
            !isJustSaving ? [{ required: true, message: '请填写联系人' }] : []
          "
        />

        <van-field
          v-model="form.contactPhone"
          :required="!isJustSaving"
          name="联系人电话"
          label="联系人电话"
          placeholder="联系人电话"
          :rules="
            !isJustSaving
              ? [{ required: true, message: '请填写联系人电话' }]
              : []
          "
        />

        <!-- v-model="form.sms" -->
        <van-field
          :required="!isJustSaving"
          name="是否发信息给主办方"
          label="是否发信息给主办方"
          placeholder="是否发信息给主办方"
        >
          <template #input>
            <van-radio-group v-model="form.sms" direction="horizontal">
              <van-radio :name="true">是</van-radio>
              <van-radio :name="false">否</van-radio>
            </van-radio-group>
          </template>
        </van-field>

        <van-field
          :required="!isJustSaving && leaderRequiring"
          :rules="
            !isJustSaving && leaderRequiring
              ? [{ required: true, message: '请填写重要领导' }]
              : []
          "
          v-model="form.leader"
          name="重要领导"
          label="重要领导"
          placeholder="重要领导"
        />

        <van-field
          v-model="form.desc"
          rows="2"
          label="工作内容描述"
          type="textarea"
          placeholder="请输入工作内容描述"
        />
      </van-form>
    </div>
    <div class="bottomButtonWrapper">
      <van-button
        @click="handleSubmit(1)"
        class="bottomButton"
        plain
        type="primary"
        size="small"
        style="color:#2bb7b3;font-size:.8rem;font-weight:500;border: 1px solid #2bb7b3;border-radius:5px"
        >保存</van-button
      >
      <van-button
        @click="handleSubmit(2)"
        class="bottomButton"
        type="info"
        size="small"
        style="font-size:.8rem;font-weight:500;background-color:#2bb7b3;border: 1px solid #2bb7b3;border-radius:5px"
        >立即派单</van-button
      >
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { getOperators } from "@/api/user";
import {
  getMeetingRoomList,
  addMeeting,
  getMeetingDetails,
  editMeeting,
  sendMeeting,
  editSendMeeting,
} from "@/api/meetingList";

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      form: {
        theme: "",
        level: "",
        startTime: "",
        endTime: "",
        type: "",
        operationSids: [],
        roomId: "",
        sponsor: "",
        contacts: "",
        leader: "",
        contactPhone: "",
        sms: true,
      },
      showPicker: false,
      timeOrder: 0,
      computedStartTime: "",
      computedEndTime: "",
      meetingLevelVisible: false,
      meetingLevelColumns: [
        { text: "VIP会议", value: 1 },
        { text: "普通会议", value: 2 },
        { text: "重要会议", value: 3 },
        { text: "会议支持", value: 4 },
      ],
      computedLevel: "",
      meetingTypeColumns: [
        { text: "常规会议", value: 1 },
        { text: "视频会议", value: 2 },
        { text: "直播会议", value: 3 },
      ],
      computedMeetingType: "",
      meetingTypeVisible: false,
      operatorsList: [],
      activeIndex: 0,
      activeIds: [],
      computedOperators: "",
      meetingOperatorsVisible: false,
      meetingRoomLVisible: false,
      meetingRoomColumns: [],
      computedRoom: "",
      scrollbarObj: {
        fade: true,
      },
      isJustSaving: false,
      leaderRequiring: false,
      minDate: new Date(),
      maxDate: new Date(2100, 10, 1),
    };
  },
  //监听属性 类似于data概念
  computed: {
    currentMeeting() {
      return this.$store.state.currentMeeting.meeting;
    },
    isEditing() {
      return this.$store.state.currentMeeting.isEditing;
    },
    userAuthority() {
      return this.$store.state.user.authority
        ? JSON.parse(this.$store.state.user.authority)
        : [];
    },
    meetingAuth() {
      let meetingAuthObj = this.userAuthority.filter((item) => {
        return item.permissionName === "会议工单";
      })[0];
      return meetingAuthObj;
    },
    saveAuth() {
      let saveAuthObj = this.meetingAuth.subPermission.filter((item) => {
        return item.permissionName === "保存";
      })[0];
      return saveAuthObj ? saveAuthObj.permissionType : false;
    },
    sendMeetingAuth() {
      let sendMeetingAuthObj = this.meetingAuth.subPermission.filter((item) => {
        return item.permissionName === "立即派单";
      })[0];
      return sendMeetingAuthObj ? sendMeetingAuthObj.permissionType : false;
    },
  },
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    getAllMeetingRoom() {
      getMeetingRoomList().then((res) => {
        if (res.code === 10000) {
          // res.data.records
          let list = res.data.map((item) => {
            let obj = {
              id: item.roomId,
              text: item.roomName,
            };
            return obj;
          });
          this.meetingRoomColumns = list;
        } else {
          this.$notify({ type: "warning", message: res.message });
        }
      });
    },
    getCurrentMeetingDetails() {
      let param = {
        workorderId: this.currentMeeting.workorderId,
      };
      getMeetingDetails(param).then((res) => {
        if (res.code === 10000) {
          console.log(res.data);
          this.meetingDetails = res.data;
          if (this.meetingDetails.endTime) {
            this.computedEndTime = this.$moment(
              this.meetingDetails.endTime
            ).format("YYYY-M-D H:mm");
          }
          if (this.meetingDetails.startTime) {
            this.computedStartTime = this.$moment(
              this.meetingDetails.startTime
            ).format("YYYY-M-D H:mm");
          }
          this.computedRoom = this.meetingDetails.roomName;
          if (this.meetingDetails.level) {
            this.computedLevel = this.meetingLevelColumns.filter((item) => {
              return this.meetingDetails.level === item.value;
            })[0].text;
          }
          if (this.meetingDetails.type) {
            this.computedMeetingType = this.meetingTypeColumns.filter(
              (item) => {
                return this.meetingDetails.type === item.value;
              }
            )[0].text;
          }
          this.computedOperators = this.meetingDetails.operationNames;
          this.$set(this, "form", res.data);
        } else {
          this.$notify({ type: "warning", message: res.message });
        }
      });
    },
    openMeetingRoomLevel() {
      this.meetingRoomLVisible = true;
    },
    openOperatorsPopup() {
      this.meetingOperatorsVisible = true;
    },
    handleCloseOperatorsPopup() {
      this.form.operationSids = this.activeIds;

      if (this.activeIds.length) {
        let operatorsNames = this.activeIds.map((item) => {
          let name = this.operatorsList[0].children.filter((subItem) => {
            return subItem.id === item;
          })[0].text;
          console.log(item);
          return name;
        });
        this.computedOperators = operatorsNames.join("、");
      } else {
        this.computedOperators = "";
      }
    },
    getAllOperators() {
      getOperators().then((res) => {
        if (res.code === 10000) {
          // res.data.records
          let childrenList = res.data.map((item) => {
            let obj = {
              id: item.sid,
              text: item.phone ? item.name + ` (${item.phone})` : item.name,
            };
            return obj;
          });
          this.operatorsList = [{ text: "运维工程师", children: childrenList }];
          console.log(this.operatorsList);
        } else {
          this.$notify({ type: "warning", message: res.message });
        }
      });
    },
    onMeetingTypeConfirm(item) {
      this.form.type = item.value;
      this.computedMeetingType = item.text;
      this.meetingTypeVisible = false;
    },
    onMeetingTypeCancel() {
      this.meetingTypeVisible = false;
    },
    onMeetingRoomConfirm(item) {
      this.form.roomId = item.id;
      this.computedRoom = item.text;
      this.meetingRoomLVisible = false;
    },
    onMeetingRoomCancel() {
      this.meetingRoomLVisible = false;
    },
    onMeetingLevelConfirm(item) {
      this.form.level = item.value;
      this.computedLevel = item.text;
      if (item.text === "重要会议") {
        this.leaderRequiring = true;
      } else {
        this.leaderRequiring = false;
      }
      console.log(item);
      this.meetingLevelVisible = false;
    },
    onMeetingLevelCancel() {
      this.meetingLevelVisible = false;
    },
    openMeetingLevel() {
      this.meetingLevelVisible = true;
    },
    openMeetingType() {
      this.meetingTypeVisible = true;
    },
    handleSubmit(type) {
      if (type === 1) {
        this.isJustSaving = true;
      } else {
        this.isJustSaving = false;
      }
      console.log("pass", this.form);
      this.$nextTick(() => {
        this.$refs.formRef
          .validate()
          .then(() => {
            console.log("pass", this.form);
            this.form.send = type === 2;

            if (this.currentMeeting && this.currentMeeting.workorderId) {
              this.form.workorderId = this.currentMeeting.workorderId;
            }

            if (!this.isEditing) {
              console.log("addMeeting");

              // 判断是否为立即派单
              if (type === 2) {
                sendMeeting(this.form)
                  .then((res) => {
                    if (res.code === 10000) {
                      this.$notify({ type: "success", message: "操作成功" });
                      this.$store.commit("SetNavBarTitle", "会议工单");
                      this.$router.push("/meetingList");
                    } else {
                      this.$notify({ type: "warning", message: res.message });
                    }
                  })
                  .catch((error) => {
                    this.$notify({ type: "warning", message: error.message });
                  });
              } else {
                addMeeting(this.form)
                  .then((res) => {
                    if (res.code === 10000) {
                      this.$notify({ type: "success", message: "操作成功" });
                      this.$store.commit("SetNavBarTitle", "会议工单");
                      this.$router.push("/meetingList");
                    } else {
                      this.$notify({ type: "warning", message: res.message });
                    }
                  })
                  .catch((error) => {
                    this.$notify({ type: "warning", message: error.message });
                  });
              }
            } else {
              console.log("editMeeting");
              if (type === 2) {
                editSendMeeting(this.form)
                  .then((res) => {
                    if (res.code === 10000) {
                      this.$notify({ type: "success", message: "操作成功" });
                      this.$store.commit("SetNavBarTitle", "会议工单");
                      this.$router.push("/meetingList");
                    } else {
                      this.$notify({ type: "warning", message: res.message });
                    }
                  })
                  .catch((error) => {
                    this.$notify({ type: "warning", message: error.message });
                  });
              } else {
                editMeeting(this.form)
                  .then((res) => {
                    if (res.code === 10000) {
                      this.$notify({ type: "success", message: "操作成功" });
                      this.$store.commit("SetNavBarTitle", "会议工单");
                      this.$router.push("/meetingList");
                    } else {
                      this.$notify({ type: "warning", message: res.message });
                    }
                  })
                  .catch((error) => {
                    this.$notify({ type: "warning", message: error.message });
                  });
              }
            }
          })
          .catch(() => {});
      });
    },
    openTimePicker(order) {
      this.timeOrder = order;
      this.showPicker = true;
    },
    onConfirm(time) {
      if (this.timeOrder === 1) {
        this.form.startTime = this.$moment(time).format("YYYY-MM-DD HH:mm:ss");
        this.computedStartTime = this.$moment(time).format("YYYY-M-D H:m");
      } else {
        this.form.endTime = this.$moment(time).format("YYYY-MM-DD HH:mm:ss");
        this.computedEndTime = this.$moment(time).format("YYYY-M-D H:m");
      }
      this.showPicker = false;
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getAllOperators();
    this.getAllMeetingRoom();
    if (this.isEditing) {
      this.getCurrentMeetingDetails();
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit("SetNavBarTitle", to.meta.name);
    next();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style>
/* @import url(); 引入公共css类*/
</style>
